import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { loadingToggleAction,loginAction,
} from '../../store/actions/AuthActions';

//
import logo from '../../images/logo.png'
import textlogo from '../../images/logo-text.png'
import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";

import axios from 'axios';
import {baseURL} from '../../baseURL';
import Cookies from 'js-cookie';

function Login (props) {
	const navigate = useNavigate();
    const [email, setEmail] = useState('');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    async function onLogin(e) {
        e.preventDefault();
        // let error = false;
        // const errorObj = { ...errorsObj };
        // if (email === '') {
        //     errorObj.email = 'Email is Required';
        //     error = true;
        // }
        // if (password === '') {
        //     errorObj.password = 'Password is Required';
        //     error = true;
        // }
        // setErrors(errorObj);
        // if (error) {
        //   return ;
        // }
        // dispatch(loadingToggleAction(true));	
        // dispatch(loginAction(email, password, navigate));

        const data = {
          email: email,
          password: password
        }
        const response = await axios.post(baseURL+'/api/pub_user/login', data)
        console.log(response.data);

        if(response.data.status == 'ok') {
          // localStorage.setItem('token', response.data.token);
          Cookies.set('token', response.data.token, { expires: 1, path: '/'});
          Cookies.set('username', response.data.username, { expires: 1, path: '/'});
          Cookies.set('pub_id', response.data.pub_id, { expires: 1, path: '/'});
          window.location.href = '/daily-stat-report';
        } else {
          setErrors(response.data);
        }
    }

    return (
        <div className="login-main-page" style={{backgroundImage:"url("+ loginbg +")"}}>
          <div className="login-wrapper col-12 col-sm-9 col-md-8 col-lg-7 col-xl-6">
            <div className="col-12 p-5">
              <div className="auth-form-1">
                <div className="mb-4">
                    <img src="/images/logo_dark.png" width="124" height="50" />
                    <h3 className="text-primary mb-1 pt-5">Welcome</h3>
                    <p className="">Sign in by entering information below</p>
                </div>
                {props.errorMessage && (
                    <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                        {props.errorMessage}
                    </div>
                )}
                {props.successMessage && (
                    <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                        {props.successMessage}
                    </div>
                )}
                <form onSubmit={onLogin}>
                    <div className="form-group mb-3">
                        <label className="mb-2 ">
                          <strong>Username</strong>
                        </label>
                        <input type="text" className="form-control"
                          value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                      {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-2 "><strong>Password</strong></label>
                        <input
                          type="password"
                          className="form-control"
                          value={password}
                            onChange={(e) =>
                                setPassword(e.target.value)
                            }
                        />
                        {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                    </div>
                  <div className="form-row d-flex justify-content-between mt-4 mb-2">
                    <div className="form-group mb-3">
                      <div className="form-check custom-checkbox ms-1 ">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          id="basic_checkbox_1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="basic_checkbox_1"
                        >
                          Remember my preference
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block w-100"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
                <div className="new-account mt-2">
                  {/* <p className="">
                    Don't have an account?{" "}
                    <Link className="text-primary" to="/page-register">
                      Sign up
                    </Link>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>           
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);