import React,{useState} from "react";
import { Link, useNavigate } from "react-router-dom";
// import { connect, useDispatch,  } from 'react-redux';
// import {
//     loadingToggleAction,
//     signupAction,
// } from '../../store/actions/AuthActions';
// image
import logo from "../../images/logo-full.png";

import axios from 'axios';
import {baseURL} from '../../baseURL';
import Cookies from 'js-cookie';

const Register = (props) => {
    const [email, setEmail] = useState('');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');

    // const dispatch = useDispatch();
  	const navigate = useNavigate();

    const delay = ms => new Promise(
      resolve => setTimeout(resolve, ms)
    );

    async function onSignUp(e) {
        e.preventDefault();
        console.log("onSignUp");

        const data = {
            email: email,
            password: password
        }
        const response = await axios.post(baseURL+'/api/user/register', data)
        console.log(response.data);

        if(response.data.status === 'ok') {
          // localStorage.setItem('token', response.data.token);
          Cookies.set('token', response.data.token, { expires: 1, path: '/'});
          delay(1000);
          window.location.href = '/login';
        } else {
          setErrors(response.data);
        }
        
        // let error = false;
        // const errorObj = { ...errorsObj };
        // if (email === '') {
        //     errorObj.email = 'Email is Required';
        //     error = true;
        // }
        // if (password === '') {
        //     errorObj.password = 'Password is Required';
        //     error = true;
        // }
        // setErrors(errorObj);
        // if (error) return;
        // dispatch(loadingToggleAction(true));
        // dispatch(signupAction(email, password, navigate));
    }

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Sign up your account</h4>
                    {props.errorMessage && (
                      <div className=''>
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className=''>
                        {props.successMessage}
                      </div>
                    )}
                    <form onSubmit={onSignUp}>
                      {/* <div className="form-group mb-3">
                        <label className="mb-1 "><strong>Username</strong></label>
                        <input type="text" className="form-control" placeholder="username"/>
                      </div> */}

                      <div className="form-group mb-3">
                        <label className="mb-1 "><strong>Email</strong></label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="hello@example.com"/>
                      </div>
					            {errors.email && <div className="text-danger pb-2">{errors.email}</div>}

                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" defaultValue="Password" />
                      </div>
					            {errors.password && <div className="text-danger pb-2">{errors.password}</div>}

                      <div className="text-center mt-4">
                        <button type="submit" className="btn btn-primary btn-block" >
                          Sign up
                        </button>
                      </div>
                    </form>

                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => {
//     return {
//         errorMessage: state.auth.errorMessage,
//         successMessage: state.auth.successMessage,
//         showLoading: state.auth.showLoading,
//     };
// };

// export default connect(mapStateToProps)(Register);
export default Register;

