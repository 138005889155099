import { lazy, Suspense, useState, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import {  Route, Routes, useLocation , useNavigate , useParams, matchRoutes } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import './vendor/swiper/swiper-bundle.css';
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import axios from 'axios';
import {baseURL} from './baseURL';
import Cookies from 'js-cookie';

// const SignUp = lazy(() => import('./jsx/pages/Registration'));
const SignUp = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Registration')), 500);
  });
});

const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
	
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function App (props) {
    const [auth, setAuth] = useState(false);
    const dispatch = useDispatch();
	const navigate = useNavigate();
    useEffect(() => {
        // checkAutoLogin(dispatch, navigate);
        checkUserAuth();
    }, []);

    function useCurrentPath() {
        const location = useLocation()
        // const [{ route }] = matchRoutes(routes, location)
        console.log("route.path");
        console.log(location);
        return location
    }

    const location = useLocation()

    async function checkUserAuth() {
        // const token = localStorage.getItem('token');
        const token = Cookies.get('token');
        // console.log("token: " + token);
        const data = {
            token: token
        }
        const response = await axios.post(baseURL+'/api/pub_user/auth_check', data)
        // console.log(response.data);
        if(response.data.status == 'ok') {
            setAuth(true);
            Cookies.set('token', token, { expires: 1, path: '/'});
            if(location.pathname == '/login' || location.pathname == '/page-register' || location.pathname == '/page-forgot-password') {
                window.location.href = '/daily-stat-report';
            }
        } else {
            
            if(location.pathname != '/login' && location.pathname != '/page-register' && location.pathname != '/page-forgot-password') {
                window.location.href = '/login';
            }
            
            // console.log("route.path");
            // console.log(location.pathname);
        }
    }
    
    let routesBlog = (  
        <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            {/* <Route path='/page-register' element={<SignUp />} /> */}
            <Route path='/page-forgot-password' element={<ForgotPassword/>} />
        </Routes>
    );
    
    // if (props.isAuthenticated) {
    if (auth === true) {
		return (
			<>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <Index / >
                </Suspense>
            </>
        );
	
	}else{
		return (
			<div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {routesBlog}
                </Suspense>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

{/* export default withRouter(connect(mapStateToProps)(App));  */}
export default App;