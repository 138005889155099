import React,{Fragment,useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import ContactSlider from "../zenix/Home/ContactSlider";
import IndexTab from "../zenix/Home/IndexTab";
import { ThemeContext } from "../../../context/ThemeContext";

import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
	Button,
	Nav,
	Pagination
} from "react-bootstrap";

//Images
import Card1 from './../../../images/card/card1.jpg';
import Card2 from './../../../images/card/card2.jpg';
import Card3 from './../../../images/card/card3.jpg';
import Card4 from './../../../images/card/card4.jpg';


const MarketOverviewChart = loadable(() =>
	pMinDelay(import("../zenix/Home/MarketOverviewChart"), 1000)
);
const CurrentChart = loadable(() =>
	pMinDelay(import("../zenix/Home/CurrentChart"), 1000)
);

const cardBox = [
	{image: Card1},
	{image: Card2},
	{image: Card3},
	{image: Card4},
];

const orderdataBlog = [
	{price: '82.1', amount: '58.9', total: '134.10',},
	{price: '85.2', amount: '55.8', total: '136,12',},
	{price: '87.3', amount: '53.7', total: '138,12',},
	{price: '89.4', amount: '51.6', total: '139,12',},
	{price: '91.9', amount: '47.1', total: '140,12',},
	{price: '93.8', amount: '46.2', total: '142,12',},
	{price: '94.7', amount: '45.3', total: '145,12',},
	{price: '97.6', amount: '44.4', total: '147,12',},
];


const Individual = () => {
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "dark", label: "Dark" });
	}, []);
	const [crrency1, setCrrency1] = useState("Monthly (2023)");
	const [country1, setCountry1] = useState("Medan, IDN");

	const svg1 = (
		<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
		  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<rect x="0" y="0" width="24" height="24"></rect>
			<circle fill="#000000" cx="5" cy="12" r="2"></circle>
			<circle fill="#000000" cx="12" cy="12" r="2"></circle>
			<circle fill="#000000" cx="19" cy="12" r="2"></circle>
		  </g>
		</svg>
	);

	const active = 1;
	let items = [];
	for (let number = 1; number <= 4; number++) {
		items.push(
		<Pagination.Item key={number} active={number === active}>
			{number}
		</Pagination.Item>
		);
	}
	const pag = (size, gutter, variant, bg, circle) => (
		<Pagination
		  size={size}
		  className={`mt-4  ${gutter ? "pagination-gutter" : ""} ${
			variant && `pagination-${variant}`
		  } ${!bg && "no-bg"} ${circle && "pagination-circle"}`}
		>
		  <li className="page-item page-indicator">
			<Link className="page-link" to="#">
			  <i className="la la-angle-left" />
			</Link>
		  </li>
		  {items}
		  <li className="page-item page-indicator">
			<Link className="page-link" to="#">
			  <i className="la la-angle-right" />
			</Link>
		  </li>
		</Pagination>
	);
	

		return(
			<Fragment>
				<div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
					<h2 className="font-w600 mb-2 me-auto ">Manage Projects</h2>
					{/* <div className="weather-btn mb-2">
						<span className="me-3 fs-16 font-w600 text-black"><i className="fa fa-cloud me-2"></i>21</span>
						<Dropdown>
							<Dropdown.Toggle variant="" as="div" className="form-control style-1 default-select me-3 p-0">{country1}</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => setCountry1("Medan, IDN")}>Medan, IDN</Dropdown.Item>
								<Dropdown.Item onClick={() => setCountry1("Jakarta, IDN")}>Jakarta, IDN</Dropdown.Item>
								<Dropdown.Item onClick={() => setCountry1("Surabaya, IDN")}>Surabaya, IDN</Dropdown.Item>
							 </Dropdown.Menu>
						</Dropdown>
					</div>*/}
					<Link to={"/projects-to-studios"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link>
				</div>
				<div className="row">
					<div className="col-xl-12 col-xxl-12">
						<div className="card">
							<div className="card-body pb-4">
								<p className="mb-0 text-black">ID: 1</p>
								<p className="mb-0 text-black">Project Name: Project One</p>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-xl-12 col-xxl-12">
						<div className="card">
							<div className="card-header border-0 flex-wrap pb-0 select-btn">
								<div className="mb-3">
									<h4 className="fs-20 text-black">Studios</h4>
									<p className="mb-0 fs-12 text-black">List of studios</p>
								</div>
							</div>
							<div className="card-body pb-4">
								<Table responsive>
									<thead>
										<tr>
											<th className="width80">
												<strong>#</strong>
											</th>
											<th>
												<strong>Studio name</strong>
											</th>
											<th>
												<strong>Action</strong>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<strong>01</strong>
											</td>
											<td>
												<strong>Studio One</strong>
											</td>
											<td>
												<Link to="#">
													<Button className="me-2" variant="info">
														Assign to this studio
													</Button>
												</Link>
											</td>
										</tr>
										<tr>
											<td>
												<strong>02</strong>
											</td>
											<td>
												<strong>Studio Two</strong>
											</td>
											<td>
												<Link to="#">
													<Button className="me-2" variant="primary">
														Unassign
													</Button>
												</Link>
											</td>
										</tr>
									</tbody>
								</Table>

								<Nav>
									{pag("sm", true, "info", false, false)}
								</Nav>
							</div>
						</div>
					</div>
				</div>

			</Fragment>
		)
	
}
export {orderdataBlog};
export default Individual;