import React,{Fragment,useState, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import { Button, Alert } from "react-bootstrap";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import {reducer} from '../bootstrap/alertReducer';
import DatePicker  from "react-datepicker";
import { useSearchParams, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
// import Select from "react-select";
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import "bootstrap-daterangepicker/daterangepicker.css";
// import { Bar } from "react-chartjs-2";
// import 'chartjs-plugin-datalabels';
// import { ThemeContext } from "../../../context/ThemeContext";

const emojis = {
    success: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polyline points='9 11 12 14 22 4'></polyline>
            <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
        </svg>
    ),

    error: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
            <line x1='15' y1='9' x2='9' y2='15'></line>
            <line x1='9' y1='9' x2='15' y2='15'></line>
        </svg>
    )
}
const initial = true;

const ChangePassword = () => {
    let navigate = useNavigate();
	// useEffect(() => {

	// }, []);

    const [searchParams] = useSearchParams();

    const [state, dispatch] = useReducer(reducer, initial);

    const [advertiserId, setAdvertiserId] = useState('yes');

    const [activeStatus, setActiveStatus] = useState('yes');
    
    const [password, setPassword] = useState('');
    const [password_old, setOldPassword] = useState('');
    const [password_confirm, setPasswordConfirm] = useState('');

    const [alertStatus, setAlertStatus] = useState('');
    const [alertText, setAlertText] = useState('');

    async function saveNewPassword() {
        const token = Cookies.get('token');
		const data = {
            token: token,
            password_old: password_old,
            password: password,
            password_confirm: password_confirm,
        }

        const response = await axios.post(baseURL+'/api/pub_user/password/reset', data)
        console.log(response.data);
        setAlertStatus(response.data.status);

        if(response.data.status === 'error') {
            setAlertText(response.data.text);
        }
	}


    function changeOldPassword(event) {
        setOldPassword(event.target.value);
    }

    function changePassword(event) {
        setPassword(event.target.value);
    }

    function changePasswordConfirm(event) {
        setPasswordConfirm(event.target.value);
    }

    return(
        <Fragment>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Change password</h2>

                {/* <Select
                    options={optionList}
                    placeholder="Select color"
                    value={selectedOptions}
                    onChange={handleSelect}
                    isSearchable={true}
                    isMulti={true}
                    className="react-select-container"
                    classNamePrefix="react-select"
                /> */}

                {/* <div className="weather-btn mb-2">
                    <span className="me-3 fs-16 font-w600 text-black"><i className="fa fa-cloud me-2"></i>21</span>
                    <Dropdown>
                        <Dropdown.Toggle variant="" as="div" className="form-control style-1 default-select me-3 p-0">{country1}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setCountry1("Medan, IDN")}>Medan, IDN</Dropdown.Item>
                            <Dropdown.Item onClick={() => setCountry1("Jakarta, IDN")}>Jakarta, IDN</Dropdown.Item>
                            <Dropdown.Item onClick={() => setCountry1("Surabaya, IDN")}>Surabaya, IDN</Dropdown.Item>
                            </Dropdown.Menu>
                    </Dropdown>
                </div> */}
                {/* <Link to={"/advertiser-management"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link> */}
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        {/* <div className="card-header border-0 flex-wrap pb-0 select-btn">
                            <div className="mb-3">
                                <h4 className="fs-20 text-black">Market Overview</h4>
                                <p className="mb-0 fs-12 text-black">Lorem ipsum dolor sit amet, consectetur</p>
                            </div>
                        </div> */}
                        <div className="card-body">

                            <form className="form-valide" action="#" method="post" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-old-password">Current password</label>
                                        <div className="col-lg-6">
                                            <input type="password" className="form-control" id="val-old-password" value={password_old} onChange={(event) => changeOldPassword(event)} placeholder="Current password"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-new-password">New password</label>
                                        <div className="col-lg-6">
                                            <input type="password" className="form-control" id="val-new-password" value={password} onChange={(event) => changePassword(event)} placeholder="New password"/>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-password-confirm">Password confirm</label>
                                        <div className="col-lg-6">
                                            <input type="password" className="form-control" id="val-password-confirm" value={password_confirm} onChange={(event) => changePasswordConfirm(event)} placeholder="Password confirm"/>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <Button className="me-2" variant="info" onClick={() => saveNewPassword()}>
                                Save
                            </Button>

                            {alertStatus === 'error' && (
                                <Alert  variant="danger" dismissible show={state.altdanger} className='alert-alt mt-3'>
                                    {emojis.error}
                                    <strong>Error! </strong> {alertText}
                                    <button className="btn-close" onClick={()=>dispatch({type:'altdanger'})}></button>
                                </Alert>
                            )}

                            {alertStatus === 'ok' && (
                                <Alert variant="success" dismissible show={state.altsuccess} className='alert-alt mt-3'>
                                    {emojis.success}
                                    <strong>Success! </strong> <span>The password has been updated.</span>
                                    <button className="btn-close" onClick={()=>dispatch({type:'altsuccess'})}></button>
                                </Alert>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
	
}

export default ChangePassword;