import React,{Fragment,useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import ContactSlider from "../zenix/Home/ContactSlider";
import IndexTab from "../zenix/Home/IndexTab";
import { ThemeContext } from "../../../context/ThemeContext";


import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
  } from "react-bootstrap";

import { Bar } from "react-chartjs-2";
import 'chartjs-plugin-datalabels';

//Images
import Card1 from './../../../images/card/card1.jpg';
import Card2 from './../../../images/card/card2.jpg';
import Card3 from './../../../images/card/card3.jpg';
import Card4 from './../../../images/card/card4.jpg';

import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";


const MarketOverviewChart = loadable(() =>
	pMinDelay(import("../zenix/Home/MarketOverviewChart"), 1000)
);
const CurrentChart = loadable(() =>
	pMinDelay(import("../zenix/Home/CurrentChart"), 1000)
);

const cardBox = [
	{image: Card1},
	{image: Card2},
	{image: Card3},
	{image: Card4},
];

const orderdataBlog = [
	{price: '82.1', amount: '58.9', total: '134.10',},
	{price: '85.2', amount: '55.8', total: '136,12',},
	{price: '87.3', amount: '53.7', total: '138,12',},
	{price: '89.4', amount: '51.6', total: '139,12',},
	{price: '91.9', amount: '47.1', total: '140,12',},
	{price: '93.8', amount: '46.2', total: '142,12',},
	{price: '94.7', amount: '45.3', total: '145,12',},
	{price: '97.6', amount: '44.4', total: '147,12',},
];


const Enterprise = () => {
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "dark", label: "Dark" });
	}, []);
	const [crrency1, setCrrency1] = useState("Monthly (2023)");
	const [country1, setCountry1] = useState("Medan, IDN");

	const chackbox = document.querySelectorAll(".bs_exam_topper input");
  	const motherChackBox = document.querySelector(".bs_exam_topper_all input");
	const chackboxFun = (type) => {
		for (let i = 0; i < chackbox.length; i++) {
		  const element = chackbox[i];
		  if (type === "all") {
			if (motherChackBox.checked) {
			  element.checked = true;
			} else {
			  element.checked = false;
			}
		  } else {
			if (!element.checked) {
			  motherChackBox.checked = false;
			  break;
			} else {
			  motherChackBox.checked = true;
			}
		  }
		}
	  };

	  const chartDataStudioRMSE = {
		defaultFontFamily: "Poppins",
		labels: ["Studio A", "Studio B", "Studio C", "Studio D", "Studio E"],
		datasets: [
		  {
			label: "",
			backgroundColor: "#6098DA",
			hoverBackgroundColor: "#6098DA",
			data: ["0", "0", "0", "0", "10"],
		  },
		  {
			label: "",
			backgroundColor: "#E6CA84",
			hoverBackgroundColor: "#E6CA84",
			data: ["0", "0", "29", "29", "0"],
		  },
		  {
			label: "",
			backgroundColor: "#D44244",
			hoverBackgroundColor: "#D44244",
			data: ["70", "59", "0", "0", "0"],
		  },
		],
	  };
	  const chartOptionsStudioRMSE = {
		// indexAxis: 'y',
		plugins:{
			legend: {
			  display: false,
			},
			title: {
			  display: false,
			},
			tooltips: {
			  mode: "index",
			  intersect: false,
			},
			responsive: true,
		},
		scales: {
		  x:
			{
			  stacked: true,
			},
		  y:
			{
			  stacked: true,
			},
		},
	  };

	  const chartDataConstrucatability = {
		defaultFontFamily: "Poppins",
		labels: ["Studio 1", "Studio 2", "Studio 3", "Studio 4", "Studio 5", "Studio 6", "Studio 7", "Studio 8", "Studio 9", "Studio 10", "Studio 11", "Studio 12", "Studio 13", "Studio 14", "Studio 15", "Studio 16", "Studio 17", "Studio 18", "Studio 19", "Studio 20", "Studio 21"],
		datasets: [
		  {
			label: "",
			backgroundColor: "#6098DA",
			hoverBackgroundColor: "#6098DA",
			data: ["100", "95", "88", "88", "82", "82", "76", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
		  },
		  {
			label: "",
			backgroundColor: "#E6CA84",
			hoverBackgroundColor: "#E6CA84",
			data: ["0", "0", "0", "0", "0", "0", "0", "65", "56", "56", "56", "52", "52", "0", "0", "0", "0", "0", "0", "0", "0"],
		  },
		  {
			label: "",
			backgroundColor: "#D44244",
			hoverBackgroundColor: "#D44244",
			data: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "48", "42", "42", "32", "32", "26", "26", "26"],
		  },
		],
	  };
	  const chartOptionsConstrucatability = {
		indexAxis: 'y',
		plugins:{
			legend: {
			  display: false,
			},
			title: {
			  display: false,
			},
			tooltips: {
			  mode: "index",
			  intersect: false,
			},
			responsive: true,
		},
		scales: {
		  x:
			{
			  stacked: true,
			  position: 'top'
			},
		  y:
			{
			  stacked: true,
			},
		},
		tooltips: {
			callbacks: {
			  label: function(tooltipItem, data) {
				return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
			  }
			}
		  }
	  };

	  
	  const chartDataIssues = {
		defaultFontFamily: "Poppins",
		labels: [""],
		datasets: [
		  {
			label: "",
			backgroundColor: "#6098DA",
			hoverBackgroundColor: "#6098DA",
			data: ["67"],
		  },
		  {
			label: "",
			backgroundColor: "#E6CA84",
			hoverBackgroundColor: "#E6CA84",
			data: ["33"],
		  },
		],
	  };
	  const chartOptionsIssues = {
		indexAxis: 'y',
		plugins: {
			datalabels: {
			  display: true,
			  fontSize: 40,
			fontColor: '#fff'
			}
		},
		  pieceLabel: {
			render: function (args) {
					 return args.value + '%';
				   },
			fontSize: 40,
			fontColor: '#fff'
		  },
		scales: {
		  x:
			{
			  stacked: true,
			  position: 'top',
			  ticks: {
				min: 0,
				max: 100,
				callback: function(value) {
					return value + "%"
				}
			},
			},
		  y:
			{
			  stacked: true,
			},
		},
	  };

		return(
			<Fragment>
				<div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
					<h2 className="font-w600 mb-2 me-auto ">Enterprise</h2>
					{/* <div className="weather-btn mb-2">
						<span className="me-3 fs-16 font-w600 text-black"><i className="fa fa-cloud me-2"></i>21</span>
						<Dropdown>
							<Dropdown.Toggle variant="" as="div" className="form-control style-1 default-select me-3 p-0">{country1}</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => setCountry1("Medan, IDN")}>Medan, IDN</Dropdown.Item>
								<Dropdown.Item onClick={() => setCountry1("Jakarta, IDN")}>Jakarta, IDN</Dropdown.Item>
								<Dropdown.Item onClick={() => setCountry1("Surabaya, IDN")}>Surabaya, IDN</Dropdown.Item>
							 </Dropdown.Menu>
						</Dropdown>
					</div>
					<Link to={"#"} className="btn btn-secondary text-white mb-2"><i className="las la-calendar scale5 me-3"></i>Filter Periode</Link> */}
				</div>

				<div className="row">
					<div className="col-3 col-xxl-3 p-1">
						<div className="card">
							<div className="card-header border-0 flex-wrap pb-0 select-btn">
								<div className="mb-3">
									<h4 className="fs-20 text-black">Utilization forecast</h4>
									<p className="mb-0 fs-12 text-black">Average RMSE by Studio</p>
								</div>
								<Link to={"#"} className="btn btn-outline-light text-white mb-2"><i className="las la-calendar scale5 me-3"></i>Next 4 weeks</Link>
							</div>
							<div className="card-body pb-0">
								<div className="col-12">
									<div style={{display: 'flex', width: 'fit-content', float: 'right'}}>
										<div className='float-left' style={{display: 'flex', marginLeft:'8px', marginRight:'8px'}}>
											<div className="float-left" style={{background: '#D44244', borderRadius: '8px', width: '26px', height: '26px'}}></div>
											<div className="float-left fs-12 text-grey" style={{width: 'fit-content', paddingLeft: '5px', paddingTop: '3px'}}>above 80% utilization</div>
										</div>
										<div className='float-left' style={{display: 'flex', marginLeft:'8px', marginRight:'8px'}}>
											<div className="float-left" style={{background: '#E6CA84', borderRadius: '8px', width: '26px', height: '26px'}}></div>
											<div className="float-left fs-12 text-grey" style={{width: 'fit-content', paddingLeft: '5px', paddingTop: '3px'}}>between 60-80% utilization</div>
										</div>
										<div className='float-left' style={{display: 'flex', marginLeft:'8px', marginRight:'8px'}}>
											<div className="float-left" style={{background: '#6098DA', borderRadius: '8px', width: '26px', height: '26px'}}></div>
											<div className="float-left fs-12 text-grey" style={{width: 'fit-content', paddingLeft: '5px', paddingTop: '3px'}}>below 60% utilization</div>
										</div>
									</div>
								</div>
							</div>

							<div className="card-body pb-0">
								<Table responsive>
									<thead>
									<tr>
										<th style={{textTransform:'none', fontWeight: '400'}}>Team</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>HC</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>16-Jan</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>12-Feb</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>3-Mar</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>1-May</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>4-Apr</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>31-Jun</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>01-Jul</th>
									</tr>
									</thead>
									<tbody>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
									</tbody>
								</Table>
							</div>

							<div className="card-body pb-0">
								<Table responsive>
									<thead>
									<tr>
										<th style={{textTransform:'none', fontWeight: '400'}}>Team</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>HC</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>16-Jan</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>12-Feb</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>3-Mar</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>1-May</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>4-Apr</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>31-Jun</th>
										<th className="text-center" style={{textTransform:'none', fontWeight: '400'}}>01-Jul</th>
									</tr>
									</thead>
									<tbody>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
										<tr className="text-white pt-0 pb-0 mt-0 mb-0">
											<td className="text-white pt-1 pb-1 mt-0 mb-0">AS / Team Perci</td>
											<td className="text-center pt-1 pb-1 mt-0 mb-0">11</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#E09D6F'}}>65.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#D6504B'}}>100.0%</td>
											<td className="text-center text-white pt-1 pb-1 mt-0 mb-0" style={{background:'#699BD4'}}>0.0%</td>
										</tr>
									</tbody>
								</Table>
							</div>
						</div>
					</div>

					<div className="col-3 col-xxl-3 p-1">
						<div className="">
							<div className="card col-12 p-0 mb-2">
								<div className="card-header border-0 flex-wrap pb-0 select-btn">
									<div className="mb-3">
										<h4 className="fs-20 text-black">Studio RMSE</h4>
										<p className="mb-0 fs-12 text-black">Average RMSE by Studio</p>
									</div>
									<Link to={"#"} className="btn btn-outline-light text-white mb-2"><i className="las la-calendar scale5 me-3"></i>Next 4 weeks</Link>
								</div>

								<div className="card-body pb-0">
									<div className="col-12">
										<div style={{display: 'flex', width: 'fit-content', float: 'right'}}>
											<div className='float-left' style={{display: 'flex', marginLeft:'8px', marginRight:'8px'}}>
												<div className="float-left" style={{background: '#D44244', borderRadius: '8px', width: '26px', height: '26px'}}></div>
												<div className="float-left fs-12 text-grey" style={{width: 'fit-content', paddingLeft: '5px', paddingTop: '3px'}}>Over 50 PTS</div>
											</div>
											<div className='float-left' style={{display: 'flex', marginLeft:'8px', marginRight:'8px'}}>
												<div className="float-left" style={{background: '#E6CA84', borderRadius: '8px', width: '26px', height: '26px'}}></div>
												<div className="float-left fs-12 text-grey" style={{width: 'fit-content', paddingLeft: '5px', paddingTop: '3px'}}>Between 10 - 50 PTS</div>
											</div>
											<div className='float-left' style={{display: 'flex', marginLeft:'8px', marginRight:'8px'}}>
												<div className="float-left" style={{background: '#6098DA', borderRadius: '8px', width: '26px', height: '26px'}}></div>
												<div className="float-left fs-12 text-grey" style={{width: 'fit-content', paddingLeft: '5px', paddingTop: '3px'}}>Under 10 PTS</div>
											</div>
										</div>
									</div>
								</div>

								<div className="card-body pb-0">
									<Bar data={chartDataStudioRMSE} height={150} options={chartOptionsStudioRMSE} />
								</div>
							</div>

							<div className="card col-12 p-0 mt-1">
								<div className="card-header border-0 flex-wrap pb-0 select-btn">
									<div className="mb-3">
										<h4 className="fs-20 text-black">Asana comments</h4>
										<p className="mb-0 fs-12 text-black">Asana comments feedback feed</p>
									</div>
									<Link to={"#"} className="btn btn-outline-light text-white mb-2"><i className="las la-calendar scale5 me-3"></i>Last 24 hours</Link>
								</div>
								<div className="card-body pb-0">
									<div className="bubble bubble-bottom-left p-0 mb-5">

										<div className="card-body pb-0">
											<div className="col-12">
												<div style={{display: 'flex', width: 'fit-content', float: 'right'}}>
													<div className='float-left' style={{display: 'flex', marginLeft:'8px', marginRight:'8px'}}>
														<div className="float-left fs-12 text-grey" style={{width: 'fit-content', paddingTop: '8px'}}>2:23 PM - 03.03.2023</div>
													</div>
													<div className='float-left' style={{display: 'flex', marginLeft:'8px'}}>
														<Link to={"#"} className="btn btn-sm btn-outline-light text-white mb-2">John Example</Link>
													</div>
												</div>
											</div>
										</div>
										
										<div className="card-body mt-2">
											<h1 className="fs-26 text-black">Thanks for the quick response! It's great to see that the team is working together to resolve this issue!</h1>
											<div style={{display: 'flex', width: 'fit-content'}}>
												<div className="float-left" style={{marginRight: '10px'}}>
													<img src="/images/img1.png" width="100px" height="100px" />
												</div>
												<div className="float-left" style={{marginRight: '10px'}}>
													<img src="/images/img1.png" width="100px" height="100px" />
												</div>
											</div>

											<div className="col-12">
												<div className="fs-12 text-grey" style={{textAlign: 'right'}}>Precogs Board</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-3 col-xxl-3 p-1">
						<div className="card">
							<div className="card-header border-0 flex-wrap pb-0 select-btn">
								<div className="mb-3">
									<h4 className="fs-20 text-black">Construcatability</h4>
									<p className="mb-0 fs-12 text-black">Constructability coverage by Studio</p>
								</div>
								<Link to={"#"} className="btn btn-outline-light text-white mb-2"><i className="las la-calendar scale5 me-3"></i>Last 4 weeks</Link>
							</div>
							<div className="card-body pb-0">
								<div className="col-12">
									<div style={{display: 'flex', width: 'fit-content', float: 'right'}}>
										<div className='float-left' style={{display: 'flex', marginLeft:'8px', marginRight:'8px'}}>
											<div className="float-left" style={{background: '#D44244', borderRadius: '8px', width: '26px', height: '26px'}}></div>
											<div className="float-left fs-12 text-grey" style={{width: 'fit-content', paddingLeft: '5px', paddingTop: '3px'}}>Above 75%</div>
										</div>
										<div className='float-left' style={{display: 'flex', marginLeft:'8px', marginRight:'8px'}}>
											<div className="float-left" style={{background: '#E6CA84', borderRadius: '8px', width: '26px', height: '26px'}}></div>
											<div className="float-left fs-12 text-grey" style={{width: 'fit-content', paddingLeft: '5px', paddingTop: '3px'}}>Between 50-70%</div>
										</div>
										<div className='float-left' style={{display: 'flex', marginLeft:'8px', marginRight:'8px'}}>
											<div className="float-left" style={{background: '#6098DA', borderRadius: '8px', width: '26px', height: '26px'}}></div>
											<div className="float-left fs-12 text-grey" style={{width: 'fit-content', paddingLeft: '5px', paddingTop: '3px'}}>Below 50%</div>
										</div>
									</div>
								</div>
							</div>

							<div className="card-body pb-0">
								<Bar data={chartDataConstrucatability} height={360} options={chartOptionsConstrucatability} />
							</div>

						</div>
					</div>

					<div className="col-3 col-xxl-3 p-1">
						<div className="">
							<div className="card col-12 p-0 mb-2">
								<div className="card-header border-0 flex-wrap pb-0 select-btn">
									<div className="mb-3">
										<h4 className="fs-20 text-black">Lessons learned</h4>
										<p className="mb-0 fs-12 text-black">Value of all comments in Precogs</p>
									</div>
									<Link to={"#"} className="btn btn-outline-light text-white mb-2"><i className="las la-calendar scale5 me-3"></i>All time</Link>
								</div>

								<div className="card-body mt-5 pb-5 mb-5">
									<h1 style={{fontSize: '5.5rem'}}>$1,200,080</h1>
									<span className="text-white" style={{fontSize: '1.5rem'}}>Saved using Precogs so far</span>
								</div>
							</div>

							<div className="card col-12 p-0 mt-1" style={{minHeight:'600px'}}>
								<div className="card-header border-0 flex-wrap pb-0 select-btn">
									<div className="mb-3">
										<h4 className="fs-20 text-black">Issues count</h4>
										<p className="mb-0 fs-12 text-black">Amount of closed and open issues</p>
									</div>
									<Link to={"#"} className="btn btn-outline-light text-white mb-2"><i className="las la-calendar scale5 me-3"></i>Last 4 weeks</Link>
								</div>
								<div className="card-body pb-0">
									<div className="col-12">
										<div style={{display: 'flex', width: 'fit-content', float: 'right'}}>
											<div className='float-left' style={{display: 'flex', marginLeft:'8px', marginRight:'8px'}}>
												<div className="float-left" style={{background: '#6098DA', borderRadius: '8px', width: '26px', height: '26px'}}></div>
												<div className="float-left fs-12 text-grey" style={{width: 'fit-content', paddingLeft: '5px', paddingTop: '3px'}}>Closed Issues</div>
											</div>
											<div className='float-left' style={{display: 'flex', marginLeft:'8px', marginRight:'8px'}}>
												<div className="float-left" style={{background: '#E6CB82', borderRadius: '8px', width: '26px', height: '26px'}}></div>
												<div className="float-left fs-12 text-grey" style={{width: 'fit-content', paddingLeft: '5px', paddingTop: '3px'}}>Open Issues</div>
											</div>
										</div>
									</div>
								</div>

								<div className="card-body pb-0">
									<Bar data={chartDataIssues} height={150} options={chartOptionsIssues} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	
}
export {orderdataBlog};
export default Enterprise;