import React,{Fragment,useState, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import { Button, Alert, Modal } from "react-bootstrap";
// import { ThemeContext } from "../../../context/ThemeContext";

// import Select from "react-select";
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import "bootstrap-daterangepicker/daterangepicker.css";

// import { Bar } from "react-chartjs-2";
// import 'chartjs-plugin-datalabels';

import axios from 'axios';
import {baseURL} from '../../../baseURL';

import {reducer} from '../bootstrap/alertReducer';

import DatePicker  from "react-datepicker";

import { useSearchParams } from "react-router-dom";
import { SketchPicker } from 'react-color';
import Cookies from 'js-cookie';

const emojis = {
    success: (
        <svg
            viewBox='0 0 24 24'
            width='24'
            height='24'
            stroke='currentColor'
            strokeWidth='2'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='me-2'
        >
        <polyline points='9 11 12 14 22 4'></polyline>
        <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
        </svg>
    ),

    error: (
        <svg
          viewBox='0 0 24 24'
          width='24'
          height='24'
          stroke='currentColor'
          strokeWidth='2'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='me-2'
        >
          <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
          <line x1='15' y1='9' x2='9' y2='15'></line>
          <line x1='9' y1='9' x2='15' y2='15'></line>
        </svg>
    )
}
const initial = true;

const DataImportAPI = () => {
    const [basicModal, setBasicModal] = useState(false);
	useEffect(() => {
        getAPI();
	}, []);

    const [searchParams] = useSearchParams();
    const [state, dispatch] = useReducer(reducer, initial);
    const [api, setAPI] = useState('');
    const [token, setToken] = useState('');

    async function getAPI() {
		const data = {
            token: Cookies.get('token')
        }
        const response = await axios.post(baseURL+'/api/pub/publisher_get_api', data)
        console.log(response.data);
        var report_api = baseURL + '/api/pub/get?token=' + response.data[0].api_token + '&dateFrom=2023-01-31&dateTo=2023-04-01&daily=0';
        setAPI(report_api);
        setToken(response.data[0].api_token);
	}

    return(
        <Fragment>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Data Import API</h2>

                {/* <Select
                    options={optionList}
                    placeholder="Select color"
                    value={selectedOptions}
                    onChange={handleSelect}
                    isSearchable={true}
                    isMulti={true}
                    className="react-select-container"
                    classNamePrefix="react-select"
                /> */}

                {/* <div className="weather-btn mb-2">
                    <span className="me-3 fs-16 font-w600 text-black"><i className="fa fa-cloud me-2"></i>21</span>
                    <Dropdown>
                        <Dropdown.Toggle variant="" as="div" className="form-control style-1 default-select me-3 p-0">{country1}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setCountry1("Medan, IDN")}>Medan, IDN</Dropdown.Item>
                            <Dropdown.Item onClick={() => setCountry1("Jakarta, IDN")}>Jakarta, IDN</Dropdown.Item>
                            <Dropdown.Item onClick={() => setCountry1("Surabaya, IDN")}>Surabaya, IDN</Dropdown.Item>
                            </Dropdown.Menu>
                    </Dropdown>
                </div> */}
                {/* <Link to={"/publisher-management"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link> */}
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        {/* <div className="card-header border-0 flex-wrap pb-0 select-btn">
                            <div className="mb-3">
                                <h4 className="fs-20 text-black">Market Overview</h4>
                                <p className="mb-0 fs-12 text-black">Lorem ipsum dolor sit amet, consectetur</p>
                            </div>
                        </div> */}
                        <div className="card-body">

                            <form className="form-valide" action="#" method="post" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">

                                    <div className="form-group mb-3">
                                        <label className="" htmlFor="val-name">API Endpoint</label>
                                        <input type="text" className="form-control" id="val-name" value={api} placeholder="API" />
                                    </div>

                                </div>
                            </form>

                            <p>
                                API Endpoint contains the following parameters:<br/>
                                * token (required)<br/>
                                * dateFrom<br/>
                                * dateTo<br/>
                                * daily<br/><br/>
                                
                                If you don't set "dateFrom" or "dateTo", it returns the data for the last 7 days.<br/>
                                If you set daily=1, it returns the summary data for each date.
                            </p>

                            <p>
                                Example of response:
                            </p>

                            <div className="row">
                                <div className="col-md-6">
                                    <span><b>daily=0</b></span><br/>
                                    <code>
                                        &#123; <br/>
                                            &nbsp;&nbsp; "status": true, <br/>
                                            &nbsp;&nbsp; "publisher": "PUBLISHER NAME", <br/>
                                            &nbsp;&nbsp; "campaign": [&#123; <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp; "campaign_name": "CAMPAIGN NAME", <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp; "data": [&#123; <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Date": "2023-01-31", <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Channel": "CHANNEL NAME", <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Country": "US", <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Impressions": 1, <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Searches": 1, <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Clicks": 1, <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Pub Rev": 0.01 <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp; &#125;, &#123; <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Date": "2023-01-31", <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Channel": "CHANNEL NAME 2", <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Country": "", <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Impressions": 1, <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Searches": 1, <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Clicks": 1, <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Pub Rev": 0.01 <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp; &#125;] <br/>
                                            &nbsp;&nbsp; &#125;] <br/>
                                        &#125; <br/>
                                    </code>
                                </div>
                                <div className="col-md-6">
                                    <span><b>daily=1</b></span><br/>
                                    <code>
                                        &#123; <br/>
                                            &nbsp;&nbsp; "status": true, <br/>
                                            &nbsp;&nbsp; "publisher": "PUBLISHER NAME", <br/>
                                            &nbsp;&nbsp; "campaign": [&#123; <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp; "campaign_name": "CAMPAIGN NAME", <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp; "data": [&#123; <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Date": "2023-01-31", <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Impressions": 1, <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Searches": 1, <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Clicks": 1, <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Pub Rev": 0.01 <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp; &#125;,&#123; <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Date": "2023-02-01", <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Impressions": 1, <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Searches": 1, <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Clicks": 1, <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Pub Rev": 0.01 <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp; &#125;] <br/>
                                            &nbsp;&nbsp; &#125;] <br/>
                                        &#125; <br/>
                                    </code>
                                </div>
                            </div>

                            

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
	
}

export default DataImportAPI;